import React, { useState } from "react";
import { Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    justifyContent: "center",
    marginTop: "40vh",
  },
}));

const Contact = () => {
  const classes = useStyles();

  return (
    <Container>
      <Box className={classes.main}>
        Email us for your queries at:
        <a
          href="mailto:vishwakarma.electricalshop@gmail.com"
          target="_blank"
          rel="noreferrer"
        >
          vishwakarma.electricalshop@gmail.com
        </a>
      </Box>
    </Container>
  );
};

export default Contact;
